.Logininputs {
  gap: unset !important;
}
.form-floating input,
.form-control,
.Logininputs input {
  border: unset !important;
  font-size: 0.9rem;
  font-weight: 200;
  border-radius: 0 !important;
}
.form-control:focus {
  border-color: unset !important;
  box-shadow: 0 15px 27px -15px rgb(213 213 213) !important;
  border-left: 7px solid #3c4b64 !important;
}
.adminLoginBackImg {
  background-image: url("");
}
.adminPanelTitle {
  font-size: 40px !important;
  font-weight: 700;
  color: #074ea2;
  padding: 3rem !important;
  text-align: center;
}
.loginOptionDiv {
  padding-top: 4rem !important;
}
.loginOptionDiv h3 {
  font-size: 1.6rem !important;
  margin-top: 0.5rem !important;
  opacity: calc(70%) !important;
  font-weight: 700 !important;
}
